const Heading = {
  baseStyle: {
    fontFamily: 'calling-code',
    fontWeight: 700,
    color: 'white',
    textTransform: 'uppercase',
  },
};

export default Heading;
